<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.9987 1.33398C4.3187 1.33398 1.33203 4.32065 1.33203 8.00065C1.33203 11.6807 4.3187 14.6673 7.9987 14.6673C11.6787 14.6673 14.6654 11.6807 14.6654 8.00065C14.6654 4.32065 11.6787 1.33398 7.9987 1.33398ZM7.9987 13.334C5.0587 13.334 2.66536 10.9407 2.66536 8.00065C2.66536 5.06065 5.0587 2.66732 7.9987 2.66732C10.9387 2.66732 13.332 5.06065 13.332 8.00065C13.332 10.9407 10.9387 13.334 7.9987 13.334ZM6.33203 11.0007L10.9987 8.00065L6.33203 5.00065V11.0007Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcPlayCircle',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>
